import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  fontWeights,
  fontSize,
  fluidFontSize,
  brandColours,
  sectionPaddings,
} from '../styles';
import { Container, Button } from './ui';
import FreeDeliveryMessage from './FreeDeliveryMessage';

const StyledImageBanner = styled.section`
  position: relative;

  ${({ isHomePage }) => {
    if (!isHomePage) {
      return css`
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${standardColours.black};

        ${minBreakpointQuery.medium`
          min-height: 450px;
        `}

        ${minBreakpointQuery.large`
          min-height: 550px;
        `}

        ${minBreakpointQuery.xxlarge`
          min-height: 650px;
        `}
      `;
    }
  }}
`;

const StyledImage = styled(GatsbyImage)`
  ${({ isHomePage }) => {
    if (isHomePage) {
      return css`
        height: 85vh;
        max-height: 900px;
      `;
    } else {
      return css`
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.6;
      `;
    }
  }}
`;

const StyledContent = styled.div`
  ${({ isHomePage }) => {
    if (isHomePage) {
      return css`
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        padding: 25px 15px;
        width: 100%;
        background: linear-gradient(
          to bottom,
          ${standardColours.transparentBlack(0)} 0%,
          ${standardColours.transparentBlack(0.7)} 100%
        );

        ${maxBreakpointQuery.smedium`
          flex-direction: column-reverse;
          align-items: center;
          text-align: center;
        `}

        ${minBreakpointQuery.smedium`
          justify-content: space-between;
          align-items: end;
          padding: 30px;
        `}

        ${minBreakpointQuery.xxxlarge`
          padding: 45px;
        `}

        ${minBreakpointQuery.xxxxlarge`
          padding: 60px;
        `}
      `;
    } else {
      return css`
        position: relative;
        ${sectionPaddings('80px', '200px')};
        text-align: center;
      `;
    }
  }}
`;

const StyledContainer = styled(Container)`
  max-width: 820px;
`;

const StyledHeading = styled.h1`
  color: ${standardColours.white};
  font-weight: ${fontWeights.bold};
  ${fluidFontSize(
    '26px',
    '42px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
`;

const StyledFreeDeliveryMessage = styled(FreeDeliveryMessage)`
  color: ${standardColours.white};
  line-height: 1.5;

  strong {
    font-weight: ${fontWeights.medium};
  }

  ${maxBreakpointQuery.small`
    ${fontSize(14)};
  `}

  ${maxBreakpointQuery.smedium`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.smedium`
    margin-right: 20px;
  `}
`;

const StyledText = styled.p`
  color: ${standardColours.white};
  line-height: 1.5;

  ${maxBreakpointQuery.small`
    ${fontSize(14)};
  `}

  ${({ isHomePage }) => {
    if (isHomePage) {
      return css`
        ${maxBreakpointQuery.smedium`
          margin-top: 20px;
        `}

        ${minBreakpointQuery.smedium`
          margin-right: 20px;
        `}
      `;
    } else {
      return css`
        margin-top: 16px;
      `;
    }
  }}
`;

const StyledButton = styled(Button)`
  ${({ isHomePage }) => {
    if (isHomePage) {
      return css`
        color: ${standardColours.black};
        font-weight: ${fontWeights.regular};
        background-color: ${brandColours.tertiary};
        border: none;

        ${minBreakpointQuery.small`
          ${fontSize(18)};
        `}

        ${minBreakpointQuery.xxxlarge`
          padding: 16px 25px;
          ${fontSize(20)};
        `}

        ${minBreakpointQuery.xxxxlarge`
          padding: 18px 28px;
          ${fontSize(22)};
        `}

        span {
          position: relative;
          padding-right: 40px;

          ${minBreakpointQuery.small`
            padding-right: 50px;
          `}

          ${minBreakpointQuery.xxxlarge`
            padding-right: 60px;
          `}

          &:after {
            content: '⟶';
            position: absolute;
            top: 50%;
            right: 0;
            font-weight: ${fontWeights.bold};
            ${fontSize(28)};
            line-height: 1;
            transform: translateY(calc(-50% - 5px));
          }
        }
      `;
    } else {
      return css`
        margin-top: 30px;
      `;
    }
  }}
`;

const ImageBanner = ({
  title,
  heading,
  image,
  mobileImage,
  text,
  link,
  isHomePage,
}) => {
  const images = mobileImage
    ? withArtDirection(image.gatsbyImageData, [
        {
          media: `(max-width: ${breakpointSizes.smedium - 1}px)`,
          image: mobileImage.gatsbyImageData,
        },
      ])
    : image.gatsbyImageData;

  return (
    <StyledImageBanner isHomePage={isHomePage}>
      <StyledImage
        image={images}
        alt={image.alt}
        isHomePage={isHomePage}
        loading="eager"
        fetchPriority="high"
      />
      {isHomePage ? (
        <StyledContent isHomePage={isHomePage}>
          {text ? (
            <StyledText isHomePage={isHomePage}>{text}</StyledText>
          ) : (
            <StyledFreeDeliveryMessage />
          )}
          <StyledButton to={link.page} alt={true} isHomePage={isHomePage}>
            <span>{link.text}</span>
          </StyledButton>
        </StyledContent>
      ) : (
        <StyledContent>
          <StyledContainer>
            <StyledHeading>{heading || title}</StyledHeading>
            {text && <StyledText>{text}</StyledText>}
            {link && (
              <StyledButton to={link.page} alt={true}>
                {link.text}
              </StyledButton>
            )}
          </StyledContainer>
        </StyledContent>
      )}
    </StyledImageBanner>
  );
};

export default ImageBanner;

export const ImageBannerFragments = graphql`
  fragment HomeDesktopImageBannerFragment on DatoCmsFileField {
    gatsbyImageData(width: 1440, height: 900, layout: FULL_WIDTH)
    alt
  }

  fragment SubDesktopImageBannerFragment on DatoCmsFileField {
    gatsbyImageData(width: 1440, height: 650, layout: FULL_WIDTH)
    alt
  }

  fragment HomeMobileImageBannerFragment on DatoCmsFileField {
    gatsbyImageData(width: 768, height: 900, layout: FULL_WIDTH)
    alt
  }
`;
