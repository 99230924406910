import React from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
  fluidFontSize,
} from '../styles';
import { Container, Button } from './ui';
import Breadcrumbs from './Breadcrumbs';

const StyledTextBanner = styled.section`
  padding: 80px 0;
  color: ${brandColours.tertiary};
  text-align: center;
  background-color: ${brandColours.primary};

  ${({ fullHeight }) => {
    if (fullHeight) {
      return `
        height: 100vh;
      `;
    }
  }}
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 22px;

  ${minBreakpointQuery.small`
    margin-bottom: 26px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 30px;
  `}
`;

const StyledHeading = styled.h1`
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.extrabold};
  ${fluidFontSize(
    '34px',
    '42px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
`;

const StyledText = styled.p`
  margin-top: 16px;
  line-height: 1.5;

  ${maxBreakpointQuery.small`
    ${fontSize(14)};
  `}
`;

const StyledContent = styled.div`
  p {
    margin-top: 16px;
    line-height: 1.5;

    ${maxBreakpointQuery.small`
      ${fontSize(14)};
    `}
  }

  a {
    text-decoration: underline;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 30px;
`;

const TextBanner = ({
  title,
  heading,
  text,
  content,
  link,
  breadcrumbs,
  children,
  removebreadcrumbs,
  fullHeight,
}) => (
  <StyledTextBanner fullHeight={fullHeight}>
    <Container narrow={true}>
      {!removebreadcrumbs && (
        <StyledBreadcrumbs
          currentPageTitle={title}
          items={breadcrumbs}
          alt={true}
        />
      )}
      <StyledHeading>{heading || title}</StyledHeading>
      {text && <StyledText>{text}</StyledText>}
      {content && (
        <StyledContent dangerouslySetInnerHTML={{ __html: content }} />
      )}
      {link && <StyledButton to={link.page}>{link.text}</StyledButton>}
    </Container>
    {children}
  </StyledTextBanner>
);

export default TextBanner;
