import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ImageBanner from '../components/ImageBanner';
import TextBanner from '../components/TextBanner';
import ModularBlocks from '../components/ModularBlocks';

const DefaultPageTemplate = ({ data: { datoCmsPage } }) => {
  const {
    locale,
    slugLocales,
    seoMetaTags,
    noIndex,
    title,
    bannerHeading,
    bannerImage,
    bannerText,
    bannerLink,
    modularBlocks,
  } = datoCmsPage;

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsPage}
      noIndex={noIndex}
    >
      <main>
        {bannerImage ? (
          <ImageBanner
            title={title}
            heading={bannerHeading}
            image={bannerImage}
            text={bannerText}
            link={bannerLink}
          />
        ) : (
          <TextBanner
            title={title}
            heading={bannerHeading}
            text={bannerText}
            link={bannerLink}
          />
        )}
        <ModularBlocks items={modularBlocks} locale={locale} />
      </main>
    </Layout>
  );
};

export const DefaultPageTemplateQuery = graphql`
  query DefaultPageTemplateQuery($id: String!) {
    datoCmsPage(id: { eq: $id }) {
      locale
      slugLocales: _allSlugLocales {
        locale
        value
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      title
      bannerHeading
      bannerImage {
        ...SubDesktopImageBannerFragment
      }
      bannerText
      bannerLink {
        text
        page {
          ...LinkFragment
        }
      }
      modularBlocks {
        ...AccordionModularBlockFragment
        ...BackgroundImageCtaModularBlockFragment
        ...ContactFormModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...CreateTradeAccountFormModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FeaturedCustomerPhotosModularBlockFragment
        ...FeaturedLogosModularBlockFragment
        ...FeaturedPointsModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...IconAccordionModularBlockFragment
        ...IconContentBlocksModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...ImageGalleryModularBlockFragment
        ...InternalVideoModularBlockFragment
        ...NumberedContentListModularBlockFragment
        ...ProjectListingModularBlockFragment
        ...ReviewsModularBlockFragment
        ...TabsModularBlockFragment
        ...TestimonialsModularBlockFragment
        ...TradeSignUpModularBlockFragment
      }
      ...LinkFragment
    }
  }
`;

export default DefaultPageTemplate;
